import {
  Option
} from '@/templates'

const legendMap = {
  'IP':{
    width:'50%',
    right:'center',
  },
  'APP':{
    width:'85%',
    right:'center',
  },
}

export class BarOption extends Option {
  constructor(title,ipVersion) {
    super()
    this.title = title
    this.chartType = title.split(' ')[0]
    this.ipVersion = ipVersion
    this.clientWidth = document.body.getBoundingClientRect().width
  }

  getLegend() {
    return {
      top: 20,
    }
  }

  getToolTip() {
    return {}
  }
  getXAxis() {
    return {
      type: 'value',
      name: 'Count',
      splitLine: {
        show: false
      },
      axisLine: {
        show: true
      },
    }
  }


  getYAxis() {
    return {
      type: 'category',
    }
  }

  getGrid() {
    return {
      top: '10%',
      height: '80%',
      width:legendMap[this.chartType].width,
      // left: 'right',
      right:legendMap[this.chartType].right,
    }
  }

  getSeries() {
    return [{
      data: [],
      label:{
        show:true,
         position: 'right',
         formatter:(params) => {
           return handleNumByDot(params.data.value)
         }
      },
      barWidth: '100%',
      type: 'bar',
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      }
    }]
  }
}

function handleNumByDot(num){
  const str = new String(num).toString()
  const numberArray = str.split('.')
  const floatNum = numberArray[1] ? '.' + numberArray[1] : ''
  var LongNum = numberArray[0]
  for(var i = 3;i < LongNum.length ;i +=4){
    LongNum = LongNum.slice(0,LongNum.length - i)+','+LongNum.slice(LongNum.length - i)
  }
  return LongNum + floatNum
}
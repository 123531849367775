<template>
  <div class="time-zone">
    <!-- <el-col :span="2"> -->
    <div class="dateZone select_item">
      <span v-if="showLable">Time zone</span>
      <el-select @focus="setMinWidth" @change="selectDateZone()" v-model="DataZone" style="width: 100%">
        <el-option
          v-for="item of DataZoneOptions"
          :key="item.value"
          :value="item.value"
          :label="item.label"
          :style="{ width: minWidth + 'px' }"
        >
          <span style="float: left">{{ item.label }}</span>
        </el-option>
      </el-select>
    </div>
    <!-- </el-col> -->
    <!-- <el-col :span="8"> -->
    <div class="time select_item">
      <span v-if="showLable">Select time period (by Start Time)</span>
      <!-- range-separator="-" -->
      <el-date-picker
        v-model="selected_time"
        type="datetime"
        start-placeholder="start-time"
        prefix-icon="el-icon-date"
        @change="timeFilter"
      >
      </el-date-picker>
    </div>

    <div class="select_item" style="margin-left: 10px">
      <el-select @focus="setMinWidth" @change="timeFilter()" v-model="LastHour" style="width: 100%">
        <!-- :popper-append-to-body="true" -->
        <el-option
          v-for="item of LastHourOptions"
          :key="item"
          :value="item"
          :label="`Last ${item} Hour${item == 1 ? '' : 's'}`"
          :style="{ width: minWidth + 'px', height: '25px', lineHeight: '25px' }"
        >
          <span style="float: left">{{ item }}</span>
        </el-option>
      </el-select>
    </div>
    <!-- </el-col> -->
  </div>
</template>

<script>
export default {
  name: 'TimeZone',
  created() {
    this.selected_time = this.privateDefaultDataArray[1]
    this.LastHour =
      (new Date(this.privateDefaultDataArray[1]).getTime() - new Date(this.privateDefaultDataArray[0]).getTime()) /
      3600000

    let selectedTime = localStorage.getItem('selectedTime')
    const storageDataZone = localStorage.getItem('dateZone')
    const storageLastHour = localStorage.getItem('lastHour')
    if (storageDataZone) {
      this.DataZone = parseInt(storageDataZone)
    }
    if (storageLastHour) {
      this.LastHour = parseInt(storageLastHour)
    }
    if (selectedTime) {
      selectedTime = JSON.parse(selectedTime)
      if (selectedTime.length == 2 && selectedTime[1] !== null) {
        this.selected_time = this.formatDate(new Date(selectedTime[1]))
        console.log('this.selected_time', this.selected_time)
      }
    }

    if (this.defaultDateArray.length !== 0) {
      this.selected_time = new Date(this.defaultDateArray[1]).getTime()
      // + this.DataZone * 60 * 60 * 1000
      this.LastHour =
        (new Date(this.defaultDateArray[1]).getTime() - new Date(this.defaultDateArray[0]).getTime()) / 3600000
    }
    // else if (selectedTime && selectedTime.length === 2 && selectedTime[0] !== null) {
    //   console.log('!22222')
    //   const dateArray = selectedTime
    //   this.selected_time = this.formatDate(new Date(dateArray[1]))
    //   // this.LastHour = (dateArray[1] - dateArray[0]) / 3600000
    // }
    //  else {
    // console.log('!333333')

    // }
  },

  data() {
    return {
      minWidth: '', // 用于存放单选框的长度
      selected_time: '',
      DataZoneOptions: [
        { label: 'GMT+12', value: -12 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+11', value: -11 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+10', value: -10 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+9', value: -9 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+8', value: -8 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+7', value: -7 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+6', value: -6 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+5', value: -5 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+4', value: -4 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+3', value: -3 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+2', value: -2 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT+1', value: -1 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT', value: 0 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-1', value: 1 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-2', value: 2 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-3', value: 3 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-4', value: 4 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-5', value: 5 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-6', value: 6 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-7', value: 7 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-8', value: 8 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-9', value: 9 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-10', value: 10 - parseInt(new Date().getTimezoneOffset() / 60) },
        { label: 'GMT-11', value: 11 - parseInt(new Date().getTimezoneOffset() / 60) },
      ],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60) - parseInt(new Date().getTimezoneOffset() / 60),
      LastHourOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      LastHour: 10,
      privateDefaultDataArray: [
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000 - 10 * 60 * 60 * 1000)
          .toJSON()
          .split('.')[0]
          .replace('T', ' ')
          .replace(/-/g, '/'),
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
          .toJSON()
          .split('.')[0]
          .replace('T', ' ')
          .replace(/-/g, '/'),
      ],
    }
  },
  props: {
    showLable: {
      type: Boolean,
      default: false,
    },
    //自定义默认值
    defaultDateArray: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    // this.DataZoneOptions = this.DataZoneOptions.map(item => {
    //   item.value -= parseInt(new Date().getTimezoneOffset() / 60)
    //   return item
    // })
    // this.DataZone -= parseInt(new Date().getTimezoneOffset() / 60)
    this.$nextTick(() => {
      this.timeFilter()
    })
  },

  methods: {
    setMinWidth(val) {
      this.minWidth = val.srcElement.clientWidth
    },
    selectDateZone() {
      // 返回所选时区 以及UTC时间
      const utcTimeArray = []
      utcTimeArray[0] =
        new Date(this.selected_time).getTime() + this.DataZone * 60 * 60000 - this.LastHour * 60 * 60 * 1000
      //  - new Date().getTimezoneOffset() * 1000 * 60
      utcTimeArray[1] = new Date(this.selected_time).getTime() + this.DataZone * 60 * 60000
      // - new Date().getTimezoneOffset() * 1000 * 60
      console.log('this.DataZone', this.DataZone)
      localStorage.setItem('dateZone', this.DataZone)

      this.$emit('changeDateZone', this.DataZone, utcTimeArray)
    },
    TransformDate(timeStr, DataZone) {
      const originDate = new Date(timeStr)
      const date = new Date(
        originDate.getTime() - DataZone * 60 * 60000
        // + new Date().getTimezoneOffset() * 60 * 1000
      )
      return this.formatDate(date)
    },
    formatDate(date) {
      console.log('formatDate', date)
      const month = date.getMonth() + 1
      const mydate = date.getDate()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()
      console.log(month, mydate, hours, minutes, seconds)
      return `${date.getFullYear()}/${month < 10 ? `0${month}` : month}/${mydate < 10 ? `0${mydate}` : mydate} ${
        hours < 10 ? `0${hours}` : hours
      }:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
    },
    timeFilter() {
      // 入参是当前时区的时间
      // 返回UTC的时间戳
      if (this.selected_time != null) {
        console.log(this.selected_time)
        // 转成UTC标准时间
        const UTC_end_time = new Date(this.selected_time).getTime()
        // - new Date().getTimezoneOffset() * 1000 * 60

        const UTC_start_time = new Date(this.selected_time).getTime() - this.LastHour * 60 * 60 * 1000
        // - new Date().getTimezoneOffset() * 1000 * 60

        // console.log([UTC_start_time, UTC_end_time])
        localStorage.setItem('selectedTime', JSON.stringify([UTC_start_time, UTC_end_time]))

        localStorage.setItem('lastHour', this.LastHour)

        this.$emit('change', [UTC_start_time, UTC_end_time], this.DataZone)
        // this.selected_time = this.TransformDate(UTC_end_time, this.DataZone)
        // const end = this.TransformDate(UTC_start_time, this.DataZone)
        // // console.debug('this.selected_time', this.selected_time, end)
      } else {
        this.$emit('change', [], this.DataZone)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.time-zone {
  display: flex;
  // background-color: #fff;

  .dateZone {
    width: 120px;
  }
  .time,
  .dateZone {
    text-align: left;
    margin-left: 10px;
    span {
      display: block;
      margin-bottom: 8px;
      font-size: var(--middle-font-size);
    }
  }
}
</style>

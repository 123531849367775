import {
  Option
} from '@/templates'

import {
  middleFontSize
} from '@/styles/font.scss'


const legendMap = {
  'IP': {
    top: 20,
    fontSize: 10,
    itemHeight: 14,
    itemGap: 3,
    itemWidth: 25,
    width: 80,
    widthForName: 220,
    gridWidth: '50%'
  },
  'APP': {
    top: 20,
    fontSize: 10,
    itemHeight: 14,
    itemGap: 3,
    itemWidth: 25,
    width: 100,
    widthForName: 70,
    gridWidth: '60%'
  },
}

export class LineOption extends Option {
  constructor({
    title,
    yunit,
    legendTitle,
    ipVersion,
    keepPoint = 2
  }) {
    super()
    this.title = title
    this.keepPoint = keepPoint
    this.chartType = title.split(' ')[0] !== 'IP' ? 'APP' : 'IP'
    this.yunit = yunit
    this.legendTitle = legendTitle
    this.clientWidth = document.body.getBoundingClientRect().width
    this.ipVersion = ipVersion
  }
  getLegend() {
    return [{
        id: 'titile',
        right: 0,
        orient: 'vertical',
        width: '100px',
        align: 'left',
        data: this.legendTitle,
        formatter: param => {
          const [name, t1, t2, t3] = param.split('||')
          return [
            `{title|${name}}`,
            `{title|${t1}${this.yunit ? '(' + this.yunit + ')' : ''}}`,
            `{title|${t2}${this.yunit ? '(' + this.yunit + ')' : ''}}`,
            `{title|${t3}${this.yunit ? '(' + this.yunit + ')' : ''}}`,
          ].join('')
        },
        textStyle: {
          rich: {
            title: {
              align: 'right',
              width: legendMap[this.chartType].width,
              fontWeight: 'bold',
              fontSize: legendMap[this.chartType].fontSize,
            },
          },
        },
      },
      {
        id: 'data',
        right: 0,
        top: legendMap[this.chartType].top,
        type: 'scroll',
        orient: 'vertical',
        width: '100px',
        align: 'left',
        itemGap: legendMap[this.chartType].itemGap,
        itemHeight: legendMap[this.chartType].itemHeight,
        itemWidth: legendMap[this.chartType].itemWidth,
        formatter: param => {
          const [name, t1, t2, t3] = param.split('||')
          if (!name) {
            return
          }
          return [
            `{name|${name}}`,
            `{a|${handleNumByDot(Number(t1).toFixed(this.keepPoint))}}`,
            `{a|${handleNumByDot(Number(t2).toFixed(this.keepPoint))}}`,
            `{a|${handleNumByDot(Number(t3).toFixed(this.keepPoint))}}`,
          ].join('')
        },
        textStyle: {
          rich: {
            name: {
              align: 'left',
              width: legendMap[this.chartType].widthForName,
              fontSize: legendMap[this.chartType].fontSize,
              fontWeight: 'bold',
            },
            a: {
              align: 'right',
              width: legendMap[this.chartType].width,
              fontSize: legendMap[this.chartType].fontSize,
            },
          },
        },
      },
    ]
  }

  getToolTip() {
    return {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      formatter: param => {
        // console.debug(param)
        return getTooltipTemplate(param, this.yunit, this.keepPoint)
      },
    }
  }

  getGrid() {
    return {
      width: legendMap[this.chartType].gridWidth,
      left: 100,
    }
  }

  getXAxis() {
    return {
      type: 'time',
      axisLabel: {
        formatter: function (value, index) {
          function deepFormat(num) {
            return num < 10 ? '0' + num : num
          }
          const date = new Date(value)
          return `${deepFormat(date.getMonth() + 1)}-${deepFormat(date.getDate())} ${deepFormat(
            date.getHours()
          )}:${deepFormat(date.getMinutes())}`
        },
      },
      splitNumber: 3,
    }
  }

  getYAxis() {
    return {
      type: 'log',
      name: this.yunit,
    }
  }
}

export class OverViewAlloffTrafficLine extends LineOption {
  // getTitle() {
  //   return {
  //     left: 'center',
  //     text: this.title,
  //     textStyle: {
  //       color: '#fff',
  //       fontSize: middleFontSize
  //     }
  //   }
  // }

  // getXAxis() {
  //   return {
  //     type: 'time',
  //     axisLabel: {
  //       color: '#fff',
  //       formatter: function (value, index) {
  //         function deepFormat(num) {
  //           return num < 10 ? '0' + num : num
  //         }
  //         const date = new Date(value)
  //         return `${deepFormat(date.getMonth() + 1)}-${deepFormat(date.getDate())} ${deepFormat(
  //           date.getHours()
  //         )}:${deepFormat(date.getMinutes())}`
  //       },
  //     },
  //     splitNumber: 3,
  //   }
  // }

  getYAxis() {
    return {
      type: 'log',
      name: this.yunit,
      // axisLabel: {
      //   color: '#fff',
      // },
      // splitLine: {
      //   lineStyle: {
      //     color:'#fff'
      //   }
      // }
    }
  }

  getGrid() {
    return {
      width: '80%',
      left: 'center',
    }
  }

  getLegend() {
    return {
      orient: 'horizontal',
      top: '20px',
      // textStyle: {
      //   color: '#fff'
      // }
    }
  }
  // getLegend() {
  //   return [{
  //       id: 'titile',
  //       right: 0,
  //       orient: 'vertical',
  //       width: '100px',
  //       align: 'left',
  //       data: this.legendTitle,
  //       formatter: param => {
  //         const [name, t1, t2, t3] = param.split('||')
  //         return [
  //           `{title|${name}}`,
  //           `{title|${t1}${this.yunit ? '(' + this.yunit + ')' : ''}}`,
  //           `{title|${t2}${this.yunit ? '(' + this.yunit + ')' : ''}}`,
  //           `{title|${t3}${this.yunit ? '(' + this.yunit + ')' : ''}}`,
  //         ].join('')
  //       },
  //       textStyle: {
  //         rich: {
  //           title: {
  //             align: 'right',
  //             width: 100,
  //             fontWeight: 'bold',
  //             fontSize: 10,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       id: 'data',
  //       right: 0,
  //       top: 20,
  //       type: 'scroll',
  //       orient: 'vertical',
  //       width: '100px',
  //       align: 'left',
  //       itemGap: 3,
  //       itemHeight: 14,
  //       itemWidth: 25,
  //       formatter: param => {
  //         const [name, t1, t2, t3] = param.split('||')
  //         if (!name) {
  //           return
  //         }
  //         return [
  //           `{name|${name}}`,
  //           `{a|${handleNumByDot(Number(t1).toFixed(this.keepPoint))}}`,
  //           `{a|${handleNumByDot(Number(t2).toFixed(this.keepPoint))}}`,
  //           `{a|${handleNumByDot(Number(t3).toFixed(this.keepPoint))}}`,
  //         ].join('')
  //       },
  //       textStyle: {
  //         rich: {
  //           name: {
  //             align: 'left',
  //             width: 100,
  //             fontSize: 10,
  //             fontWeight: 'bold',
  //           },
  //           a: {
  //             align: 'right',
  //             width: 100,
  //             fontSize: 10,
  //           },
  //         },
  //       },
  //     },
  //   ]
  // }
}

export class HomeLineOption extends LineOption {
  getLegend() {
    const legend = super.getLegend()
    delete legend[1].type
    return legend
  }
}

export class AppViewByClientLineOption extends LineOption {
  getLegend() {
    const superLegend = super.getLegend()
    return [{
        id: 'titile',
        right: 0,
        bottom: 80,
        orient: 'vertical',
        align: 'center',
        data: superLegend[0].data,
        formatter: superLegend[0].formatter,
        textStyle: {
          rich: {
            title: {
              align: 'right',
              width: 100,
              fontWeight: 'bold',
              fontSize: this.clientWidth < 1000 ? 6 : 14,
            },
          },
        },
      },
      {
        id: 'data',
        right: 0,
        bottom: 0,
        orient: 'vertical',
        height: '75px',
        type: 'scroll',
        formatter: superLegend[1].formatter,
        textStyle: {
          rich: {
            name: {
              align: 'left',
              width: 100,
              fontSize: this.clientWidth < 1000 ? 6 : 14,
              fontWeight: 'bold',
            },
            a: {
              align: 'right',
              width: 100,
              fontSize: this.clientWidth < 1000 ? 6 : 14,
            },
          },
        },
      },
    ]
  }
  getGrid() {
    return {
      left: 'center',
      width: '70%',
      height: '45%',
    }
  }
  getXAxis() {
    return {
      type: 'time',
      axisLabel: {
        formatter: function (value, index) {
          function deepFormat(num) {
            return num < 10 ? '0' + num : num
          }
          const date = new Date(value)
          return `${deepFormat(date.getMonth() + 1)}-${deepFormat(date.getDate())}\n${deepFormat(
            date.getHours()
          )}:${deepFormat(date.getMinutes())}`
        },
      },
      splitNumber: 3,
    }
  }
}

export class AppViewLineOption extends LineOption {
  getGrid() {
    return {
      height: '65%',
      width: '80%',
      top: 25,
      left: 'center',
    }
  }

  getXAxis() {
    return {
      type: 'time',
      axisLabel: {
        formatter: function (value, index) {
          function deepFormat(num) {
            return num < 10 ? '0' + num : num
          }
          const date = new Date(value)
          return `${deepFormat(date.getMonth() + 1)}-${deepFormat(date.getDate())}\n${deepFormat(
            date.getHours()
          )}:${deepFormat(date.getMinutes())}`
        },
      },
      splitNumber: 3,
    }
  }
}

export class AnomalyLineOption extends AppViewLineOption {
  getGrid() {
    return {
      height: '70%',
      width: '70%',
      top: 20,
      right: 'center',
    }
  }
  getYAxis() {
    return {
      // type: 'log',
    }
  }
}

export class FlowLineOption extends LineOption {
  getGrid() {
    return {
      width: this.chartType === 'IP' && this.ipVersion == 'v6' ? '45%' : '55%',
      left: 100,
    }
  }
}

function getTooltipTemplate(param, yunit, keepPoint) {
  let result = ``
  param.sort((b, a) => a.data[1] - b.data[1])
  param.forEach(item => {
    result += `<div style="display:flex;justify-content: space-between;"><div>${item.marker}${
      item.seriesName.split('||')[0]
    }</div><div><span style="font-weight:bold;margin-left:20px;text-align:right">${handleNumByDot(
      item.data[1].toFixed(keepPoint)
    )}\t${yunit || ''}</span></div></div>`
  })
  return `<div>${param[0].axisValueLabel}</div>
  ${result}
  `
}

function handleNumByDot(num) {
  const str = new String(num).toString()
  const numberArray = str.split('.')
  const floatNum = numberArray[1] ? '.' + numberArray[1] : ''
  var LongNum = numberArray[0]
  for (var i = 3; i < LongNum.length; i += 4) {
    LongNum = LongNum.slice(0, LongNum.length - i) + ',' + LongNum.slice(LongNum.length - i)
  }
  return LongNum + floatNum
}

export function handleLegend(param, chart, that) {
  that.scrollNowIndex = that.scrollToIndex
  that.scrollToIndex = param.scrollDataIndex
  PageTurn(that.scrollToIndex, that.scrollNowIndex, that)
}

function PageTurn(newV, oldV, that) {
  const pageSize = Math.abs(newV - oldV)
  Object.keys(that.extraOption.legend.selected).forEach((key, index) => {
    that.extraOption.legend.selected[key] = !!(index < newV + pageSize && index >= newV)
  })
  that.extraOption.legend.scrollDataIndex = newV
  that.reDraw(that.extraOption)
}

export function initPage(newV, oldV, that) {
  if (newV.length !== 0 && that.extraOption.legend) {
    that.scrollNowIndex = 0
    that.scrollToIndex = 0
  }
  that.chart.clear()
}
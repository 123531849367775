import {
  middleFontSize
} from '@/styles/font.scss'

export class Option {

  constructor() {}

  getColor(){
    return ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc','#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00']
  }

  getOption(subOptionArray) {
    //一定得在函数里面
    const optionRule = {
      'title': this.getTitle(),
      'dataZoom': this.getDataZoom(),
      'yAxis': this.getYAxis(),
      'xAxis': this.getXAxis(),
      'tooltip': this.getToolTip(),
      'legend': this.getLegend(),
      'grid': this.getGrid(),
      'geo': this.getGeo(),
      'series': this.getSeries(),
      'color':this.getColor()
    }
    const option = {}
    for (let i = 0; i < subOptionArray.length; i++) {
      const subOption = subOptionArray[i];
      const optionComponents = optionRule[subOption]
      option[subOption] = optionComponents
    }
    return option
  }
  getTitle() {
    return {
      left: 'center',
      text: this.title,
      textStyle: {
        color: '#000',
        fontSize: middleFontSize
      }
    }
  }

  getDataZoom() {
    return [{
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        start: 0,
        end: 100
      }
    ]
  }

  getYAxis() {
    return {
      max: function (value) {
        return Math.ceil(value.max + parseInt(value.max * 0.2))
      },
      min: 0,
    }
  }


  getXAxis() {
    return {
      splitNumber: 4
    }
  }


  getToolTip() {
    return {}
  }

  getLegend() {
    return {
      type: 'scroll',
      orient: 'vertical',
      align: 'left',
      top: '10%',
      right: '0px',
      formatter: function (name) {
        if (name.length > 7) {
          name = name.slice(0, 7) + '...';
          return name
        } else {
          return name
        }
      },
      itemHeight: 10,
      itemWidth: 12,
      textStyle: {
        fontSize: 10
      }
    }
  }


  getGrid() {
    return {
      width: '90%',
      left: 'center'
    }
  }

  getGeo() {
    return {
      map: this.mapName,
      nameProperty: 'name_en',
      zoom: 1.2,
      emphasis: {
        itemStyle: {
          areaColor: '' // 设置为空字符串可使颜色不变
        },
        label: {
          show: false
        }
      },
      roam: true,
      itemStyle: {
        areaColor: '#F6F3F8',
        borderColor: '#302f8d00'
      }
    }
  }

  getSeries() {
    return []
  }
}
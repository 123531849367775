<template>
  <div class="container">
    <el-row class="shadow" style="background-color: #fff">
      <el-col :span="12">
        <TimeZone
          style="margin: 0 10px; padding: 10px 0"
          @change="dataChange"
          @changeDateZone="selectDateZone"
        ></TimeZone>
      </el-col>
      <el-col
        :span="12"
        style="text-align: left; background: #fff; margin: 0 -10px; padding: 10px 0; height: 60px; line-height: 40px"
      >
        <span style="margin-right: 10px; font-weight: bold">e.g.</span>
        <span style="margin-right: 20px">2022-11-01 00:00:00</span>
        <span style="margin-right: 20px">Last 10 hours and select netflow</span>
      </el-col>
    </el-row>
    <div v-for="(item, index) in chartData" :key="index">
      <div class="shadow top-title">{{ item.name }} TOP 10</div>
      <el-row type="flex" justify="space-between">
        <el-col :span="12" class="col home-chart shadow">
          <Chart
            :chartId="`chart_byte_pie_${item.name}`"
            :option="
              new PieOption({
                title: `${item.name} TOP 10 by Bytes`,
                legendTitle: 'K Bytes',
                yunit: 'KB',
                ipVersion,
              }).getOption(['title', 'tooltip', 'series', 'legend', 'color'])
            "
            :sourceData="item.data && item.data.pie_byte_data"
          ></Chart>
        </el-col>
        <el-col :span="12" class="col home-chart shadow">
          <Chart
            :chartId="`chart_packet_pie_${item.name}`"
            :option="
              new PieOption({
                title: `${item.name} TOP 10 by Packets`,
                legendTitle: 'K Packets',
                yunit: '',
                ipVersion,
              }).getOption(['title', 'tooltip', 'series', 'legend', 'color'])
            "
            :sourceData="item.data && item.data.pie_packet_data"
          ></Chart>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="col home-row-chart shadow">
          <Chart
            :chartId="`chart_bar_${item.name}`"
            :option="
              new BarOption(`${item.name} TOP 10 by Flow Amount`, ipVersion).getOption([
                'title',
                'tooltip',
                'xAxis',
                'yAxis',
                'grid',
                'series',
                'legend',
              ])
            "
            :sourceData="item.data && item.data.bar_data"
            :extraOption="item.data.yData"
          ></Chart>
          <!-- {{ item.data.bar_data.length }} -->
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="col home-row-chart shadow">
          <Chart
            :chartId="`chart_bps_line_${item.name}`"
            :option="
              new LineOption({
                title: `${item.name} TOP 10 by Kbps`,
                yunit: 'Kbps',
                legendTitle: [line_head.name],
                ipVersion,
                keepPoint,
              }).getOption(['title', 'tooltip', 'xAxis', 'yAxis', 'grid', 'color', 'series', 'legend', 'dataZoom'])
            "
            :series="item.data && item.data.bps_line_data"
            :replaceMerge="['series']"
          ></Chart>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="col home-row-chart shadow">
          <Chart
            :chartId="`chart_pps_line_${item.name}`"
            :option="
              new LineOption({
                title: `${item.name} TOP 10 by pps`,
                yunit: 'pps',
                legendTitle: [line_head.name],
                ipVersion,
                keepPoint,
              }).getOption(['title', 'tooltip', 'xAxis', 'yAxis', 'grid', 'color', 'series', 'legend', 'dataZoom'])
            "
            :series="item.data && item.data.pps_line_data"
            :replaceMerge="['series']"
          ></Chart>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TimeZone from '@/components/TimeZone'
import { HomePieOption } from '@/templates/chart/pieTemplate'
import { BarOption } from '@/templates/chart/barTemplate'
import { HomeLineOption } from '@/templates/chart/lineTemplate'
// import Chart from '@/submodules/components/Chart'
import { getTopInfo } from '@/api/FlowApi.js'
export default {
  name: 'DataUsage',
  components: {
    // Chart,
    TimeZone,
  },
  inject: ['reload'],
  created() {
    this.ipVersion = localStorage.getItem('ip_version') || 'nf'
    // if (this.$route.params) {
    //   this.ipVersion = this.$route.params.version
    // }
  },
  data() {
    return {
      PieOption: HomePieOption,
      LineOption: HomeLineOption,
      ipVersion: 'nf',
      keepPoint: 3,
      BarOption,
      topData: [],
      colorList: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      line_head: {
        name: '||Min||Max||Avg',
        type: 'line',
        data: [],
        itemStyle: {
          color: '#00000000',
        },
        lineStyle: {
          color: '#00000000',
        },
        showSymbol: false,
      },
      //隐藏protocol
      chartData: [
        { name: 'APP', data: {} },
        // { name: 'Protocol', data: {} },
        { name: 'IP', data: {} },
      ],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
    }
  },
  props: {},
  mounted() {},
  methods: {
    async initData() {
      this.topData = await getTopInfo(this.ipVersion, ...this.dateArray)
      //隐藏protocol
      // this.topData.splice(1, 1)
      this.handleLine()
      this.handlePie()
      this.handleBar()
      this.syncChartData()
    },
    syncChartData() {
      const chartData = []
      this.topData.forEach((item, index) => {
        const obj = {}
        obj['data'] = item.data
        obj['name'] = item.name
        chartData.push(obj)
      })
      this.chartData = chartData
      console.log('this.chartData', this.chartData)
    },
    handlePie() {
      this.topData.forEach(item => {
        if (item.data.pie_byte_data.length) {
          item.data.pie_byte_data.push({
            name: '||K Bytes',
            itemStyle: { color: '#fff' },
          })
        }
        if (item.data.pie_byte_data.length) {
          item.data.pie_packet_data.push({
            name: '||K Packets',
            itemStyle: { color: '#fff' },
          })
        }
      })
    },
    handleBar() {
      this.topData.forEach(item => {
        const yData = []
        item.data.bar_data = item.data.bar_data.map((bar, index) => {
          yData.push(bar[1])
          return {
            value: bar[0],
            name: bar[1],
            itemStyle: {
              color: this.colorList[index % this.colorList.length],
            },
          }
        })
        item.data.yData = { yAxis: { data: yData } }
      })
    },
    handleLine() {
      this.topData = this.topData.map((item, index) => {
        if (item.data.bps_line_data.length) {
          item.data.bps_line_data = item.data.bps_line_data.map(line => {
            return {
              type: 'line',
              data: line.data.map(this.transformDateWithTimeZone),
              name: line.name,
              showSymbol: false,
              // symbolSize: item.name === 'ip' ? 10 : 4,
              // lineStyle: {
              //   width: item.name === 'ip' ? 0 : 2,
              // },
            }
          })
          item.data.bps_line_data.push(this.line_head)
        }
        if (item.data.pps_line_data.length) {
          item.data.pps_line_data = item.data.pps_line_data.map(line => {
            return {
              type: 'line',
              data: line.data.map(this.transformDateWithTimeZone),
              name: line.name,
              showSymbol: false,
              // showSymbol: item.name === 'ip' ? true : line.data.length <= 1,
              // symbolSize: item.name === 'ip' ? 10 : 4,
              // lineStyle: {
              //   width: item.name === 'ip' ? 0 : 2,
              // },
            }
          })
          item.data.pps_line_data.push(this.line_head)
        }
        return item
      })
    },
    transformDateWithTimeZone(lineitem) {
      lineitem[0] = lineitem[0] - this.DataZone * 60 * 60 * 1000
      return lineitem
    },
    dataChange(dateArray, DataZone) {
      console.log('DataUsage dateArray', dateArray)
      this.dateArray = dateArray
      this.DataZone = DataZone
      console.log('this.DataZone', this.DataZone)
      this.initData()
    },
    selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      this.initData()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  --chart-height: 40vh;
  --chart-row-height: 50vh;

  .top-title {
    background: #fff;
    // margin: 0 10px;
    margin-bottom: 20px;
    padding-left: 10px;
    color: #8b42a5;
    text-align: left;
    font-size: var(--subTitle-font-size);
  }

  .col {
    background-color: #fff;
    // margin: 0 10px;
  }

  .home-chart {
    height: var(--chart-height);
    width: calc(50% - 20px);
    padding: 10px;
  }

  .home-row-chart {
    height: var(--chart-row-height);
    padding: 10px;
    // width: calc(100% - 20px);
  }
}
// .shadow {
//   border-radius: var(--border-radius-deg);
//   box-shadow: var(--shadow-args);
// }
</style>
